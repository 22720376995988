import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '65, 65, 60',
		'primary-dark': '29, 29, 27',
		'accent': '236, 17, 101',
		'accent-plus': '255, 255, 255',
	},
});
